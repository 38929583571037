
/* ----------- */

.banner {
  position: relative;
  text-align: center;
  height: 11vw;
  background-color: rgb(64, 60, 60);
}

.titleBackground {
  padding-top: 1vw;
}

.titleImage {
  vertical-align: top;
  height: 12vw;
  width: 12vw;
  background-image: url(./images/eb_logo.png);
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: cover;
  background-color: transparent;
  display: inline-block;
  border-radius: 6.5vw;
  -webkit-border-radius: 10vw;
  -moz-border-radius: 2.5vw;
}
.imageBottomSpacer {
  height: 3vw;
}


@font-face {
  font-family: "BakerieSmooth";
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Black.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Bold.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Heavy.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Light.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Medium.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Regular.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Thin.otf") format("truetype");
}

.navLink {
  text-decoration: none;
  color: black;
  padding: 1vw;
  font-size: 2vw;
}

.navTable {
  padding: .5vw;
  height: 100%;
  font-size: 2vw;
  margin-left: auto;
  margin-right: auto;
  text-indent: 0em;
}

.navTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.nav {
  background-color: lightgray;
}

.bodyText {
  padding: 2vw;
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold;
  font-size: 3vw;
}

.quantityText {
  padding: 2vw;
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold;
  font-size: 2vw;
}

.formHeaderText {
  padding-top: 1vw;
  /* font-family: 'BakerieSmooth'; */
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 1.5vw;
}

.footerText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
}

.footer {
  background-color: lightgray;
}

.footerTable {
  padding: .5vw;
  height: 100%;
  font-size: 2vw;
  margin-left: auto;
  margin-right: auto;
  text-indent: 0em;

}

.footerTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.disclaimerContainer {
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.disclaimerText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  background-color: darkgrey;
  border-radius: 25px;
}

.productTable {
  padding-top: 2vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.productTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  font-family: Arial, Helvetica, sans-serif;
}

.orderTableCellLeft {
  padding: .5vw;
  font-size: 1.5vw;
  width: 40vw;
}

.productTableCellLeft {
  padding: .5vw;
  font-size: 1.5vw;
}

.productTableCellRight {
  padding: .5vw;
}

.productTableCellQty {
  /* border: solid; */
  /* border-width: 10px; */
  /* border: 10px; */
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: auto;
  vertical-align: middle;
  font-size: 1.5vw;
}

.productIngredients::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.productTitle {
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold;
  font-size: 4vw;
}

.contactText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 3vw;
}

.productImage {
  width: 20vw;
  height: 18vw;
}

.socialMediaImage {
  max-width: 100%;
  height: 2vw;
}

.orderFormText {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  font-size: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
}

.orderFormInput {
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 60%;
  vertical-align: middle;
  font-size: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
}

.modalDialog {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid rgb(6, 6, 6);
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  z-index: 2;
}

.orderButton {
    background-color: gray;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    font-weight: bold;
}

.buttonArea {
  padding-top: 3vw;
  padding-bottom: 3vw;
  text-align: center;
}

.header {
  /* position: sticky;
  top: 0px;
  z-index: 1; */
}

.noDotsList {
  list-style-type: square;
}
.orderCheckBox {
  width: 3vw;
  height: 3vw;
}

.news {
  background-color: rgb(132, 200, 234) ;
  padding: 2vw;
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vw;  
}

.printButton {
  background-color: black;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  font-weight: bold;
}

.labelButton {
  padding: 1vw;
}

.labelsText {
  padding: 2vw;
  font-style: normal;
  font-weight: bold;
  font-size: 5vw;
}
 
.buttons {
  margin: .25vw;
}

.scrollingTable {
  height: 90vh;
  overflow: auto;
}

.ingredientTable {
  height: 20vh;
  overflow: auto;
}

tr.header th {
  background: black;
  color: white;
}

.table-header {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.history_cell {    
  /* display: table;
  table-layout: fixed;
  word-wrap: break-word; */
  width: 25%;
  white-space: normal;
  word-wrap: break-word;
}

.scrollable-cell {
  display: block;
  overflow-x: scroll;
  white-space: break-spaces;
}
